import React, { useState, useEffect } from 'react';
import Select from 'react-select';


const PurchaseContract =() => {

  
  const [fixedPricingPeriodVisible, setFixedPricingPeriodVisible] = useState(false);
  const [holidayVisible, setHolidayVisible] = useState(false);
  const [pricingQuoteVisible, setPricingQuoteVisible] = useState(false);

//   const [selectedRef, setSelectedRef] = useState('');
  const [transRef, setTransRef] = useState([]);
  const [purchaseContID, setpurchaseContID] = useState([]);
  const [selectedDest, setSelectedDest] = useState('');
  const [selectedPort, setSelectedPort] = useState('');
  const [selectedDestination, setSelectedDestination] = useState('');
  const [selectedQuote, setSelectedPricingQuot] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedEsc_Desc, setSelectedEsc_Desc] = useState('');
  const [selectedPaymentTerms, setSelectedPaymentTerms] = useState(''); 
  const [selectedPricingBasis, setSelectedPricingBasis] = useState('');
  const [selectedGTC, setSelectedGTC] = useState('');
  const [selectedDemurrage, setSelectedDemurrage] = useState('');
  const [selectedTrader, setSelectedTrader] = useState('');


  const [selectedCPdesc, setSelectedCPdesc] = useState('');
  const [selectedTS, setSelectedTS] = useState('');
  const [selectedTerm, setSelectedTerm] = useState('');
  const [selectedOT, setSelectedOT] = useState('');
  const [selectedIQTB, setSelectedIQTB] = useState('');
  const [selectedPB, setSelectedPB] = useState('');
  const [selectedBrokerName, setSelectedBrokerName] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedBrokerCharges, setSelectedBrokerCharges] = useState('');
  const [selectedSellerName, setSelectedSellerName] = useState('');

  const [selectedPortfolio, setSelectedPortfolio] = useState([]);
  const [sellerNames, setSellerNames] = useState([]);

  const [portfolioOptions, setPortfolio] = useState([]);
  const [PricingQuoteOptions, setPricingQuot] = useState([]);
  const [GroupOptions, setGroup] = useState([]);
  const [TraderOptions, setTrader] = useState([]);  

  // const [PricingBasisOptions, setPricingBasis] = useState([]);




  
  const [brokerNames, setBrokerName] = useState([]);
  const [CpDesc, setCPdesc] = useState([]);
  const [traderName, setTraderName] = useState([]);
  const [narrowedDownTo, setNarrowedDownTo] = useState([]);
  const [BBL, setBBL] = useState('');
  const [MT, setMT] = useState('');
  const [ports, setPorts] = useState('');
  const [pds, setPds] = useState('');

  const [fixedPrice, setFP] = useState('');
  const [pricingPdFrom, setPricingFrom] = useState('');
  const [pricingPdTo, setPricingTo] = useState('');
  const [dueDays, setDue] = useState('');
  const [dealDate, setDealDate] = useState('');
  const [norAtDestination, setNorAtDestination] = useState('');
  const [loadingLaycanfrom, setLoadingLaycanFrom] = useState('');
  const [loadingLaycanto, setLoadingLaycanTo] = useState('');
  const [daysBy, setDaysBy] = useState('');
  const [BLdate, setBLdate] = useState('');
  const [Nordate, setNordate] = useState('');
  const [EstBLdate, setEstBLdate] = useState('');
  const [EstNordate, setEstNordate] = useState('');
  const [SC_QtyMtAir, setSC_QtyMtAir] = useState('');
  const [SC_QtyMtBBL, setSC_QtyMtBBL] = useState('');
  const [SC_SpecGra, setSC_SpecGra] = useState('');
  const [SL_SpecGra, setSL_SpecGra] = useState('');
  const [SP_CD, setSP_CD] = useState('');
  const [OT, setOT] = useState('');
  const [P_D, setP_D] = useState('');
  const [SPQ, setSPQ] = useState('');
  const [SP_Rounded, setSP_Rounded] = useState('');
  const [LayHours, setLayHours] = useState('');
  const [TradeCreatedOn, setTradeCreatedOn] = useState('');
  const [Fix_PPBF, setFix_PPBF] = useState('');
  const [Fix_PPBT, setFix_PPBT] = useState('');
  const [PPBH1, setPPBH1] = useState('');
  const [PPBH2, setPPBH2] = useState('');
  const [priceQD, setpriceQD] = useState('');





  const destinationOptions = [
    { label: "Free", value: "Free" },
    { label: "Restricted", value: "Restricted" }
  ];

  const TsOptions = [
    { label: "Spot", value: "Spot" },
    { label: "Term", value: "Term" }
  ];

  const OTOptions = [
    { label: "Buyer", value: "Buyer" },
    { label: "Seller", value: "Seller" },
    { label: "Terminal", value: "Terminal" },
    
  ];

  const IQTBOptions = [
    { label: "BL", value: "BL" },
    { label: "Out-Turn", value: "Out-Turn" }
  ];

  const PBOptions = [
    { label: "Platts Assessment", value: "Platts Assessment" },
    { label: "Futures", value: "Futures" },
    { label: "Fixed Price", value: "Fixed Price" }
  ];

  const PricingBasisOptions = [
    { label: "Select", value: "Select" },
    { label: "After BL", value: "After BL" },
    { label: "Around BL", value: "Around BL" },
    { label: "Before BL", value: "Before BL" },
    { label: "From BL", value: "From BL" },
    { label: "After NOR", value: "After NOR" },
    { label: "Around NOR", value: "Around NOR" },
    { label: "Before NOR", value: "Before NOR" },
    { label: "From NOR", value: "From NOR" },
    { label: "Fixed Pricing pd.", value: "Fixed Pricing pd." },
  ];

  const termsOptions = [
    { label: "FOB", value: "FOB" },
    { label: "CFR", value: "CFR" },
    { label: "CIF", value: "CIF" },
    { label: "DAP", value: "DAP" },
    { label: "Pipeline", value: "Pipeline" },
    { label: "Trucks", value: "Trucks" },
    { label: "In-Tank Transfer", value: "In-Tank Transfer" },
  ];

  const Esc_DescOptions = [
    { label: "No", value: "No" },
    { label: "Yes", value: "Yes" },
    
  ];

  const PaymentTermsOptions = [
    { label: "Standby IC", value: "Standby IC" },
    { label: "Documentary IC", value: "Documentary IC" },
    { label: "Open Account", value: "Open Account" },
   
  ];

  const GTCOptions = [
    { label: "Sonangol", value: "Sonangol" },
    { label: "Shell", value: "Shell" },
    { label: "BP", value: "BP" },
    { label: "ExxonMobil", value: "ExxonMobil" },
    { label: "Chevron", value: "Chevron" },
    { label: "KPC", value: "KPC" },
    { label: "TOTSA", value: "TOTSA" },
   
  ];

  const DemurrageOptions = [
    { label: "CharterParty", value: "CharterParty" },
    { label: "Afra", value: "Afra" },
   
  ];

  const UnitOptions = [
    { label: "BBL", value: "BBL" },
    { label: "mt Air", value: "mt Air" },
    { label: "mt Vac", value: "mt Vac" }
  ];

  const [brokerMTMap, setBrokerMTMap] = useState(new Map());
  const [brokerBBLMap, setBrokerBBLMap] = useState(new Map());


//   const additionalOption = { label: "Inventory", value: "inventory" };
//   const optionsWithInventory = [additionalOption, ...refs];
  

  useEffect(() => {
    fetchSRTdata();
    fetchPortfolio();
    fetchPort();
    fetchDesc();
    fetchSellerNames();
    fetchDestination();
    fetchBrokerDetails();
    fetchQuot();
    fetchGroup();
    fetchTraderNames();
  }, []);

  useEffect(() => {
    // Call calculateBrokerCharges whenever relevant state values change
    calculateBrokerCharges();
  }, [selectedBrokerName, selectedUnit, MT, BBL]);

  // Fetch purchasecontrctId and transaction ref
  const fetchSRTdata = async () => {
    try {
      const response = await fetch('https://www.skarvisystems.co.uk/Navigator/TESTING/phpBackend/fetch_STRdetails.php?Tran_Ref_No=C1TN20240300002&Purchasecontractid=1');
      const data = await response.json();
      setTransRef(data.result[0]?.transfer_reference || '');
      setpurchaseContID( data.result[0]?.purchaseContractID || '');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchPortfolio = async () => {
    try {
      const response = await fetch('https://www.skarvisystems.co.uk/Navigator/TESTING/phpBackend/fetch_portfolio.php');
      const data = await response.json();
      setPortfolio(data.result.map(item => ({ label: item.portfolio, value: item.portfolio })));
    } catch (error) {
      console.error('Error fetching names:', error);
    }
  };

  const fetchPort = async () => {
    try {
      const response = await fetch('https://www.skarvisystems.co.uk/Navigator/TESTING/phpBackend/fetch_ports.php');
      const data = await response.json();
      setPorts(data.result.map(item => ({ label: item.port, value: item.port })));
    } catch (error) {
      console.error('Error fetching ports:', error);
    }
  };


  const fetchDestination = async () => {
    try {
      const response = await fetch('https://www.skarvisystems.co.uk/Navigator/TESTING/phpBackend/fetch_destination.php');
      const data = await response.json();
      setPds(data.result.map(item => ({ label: item.destination, value: item.destination })));
    } catch (error) {
      console.error('Error fetching destination:', error);
    }
  };


 


  const handleSelectPDChange = (selectedOPtion) => {
    setSelectedDestination(selectedOPtion? selectedOPtion.value:'');
  }

  const handleSelectDestinationChange = (selectedOPtion) => {
    setSelectedDest(selectedOPtion? selectedOPtion.value:'');
  }

  //Fetch CP description

  const fetchDesc = async () => {
    try {
      const response = await fetch('https://www.skarvisystems.co.uk/Navigator/TESTING/phpBackend/fetch_cargoDesc.php');
      const data = await response.json();
      setCPdesc(data.result.map(item => ({ label: item.cargo_desc, value: item.cargo_desc })));
    } catch (error) {
      console.error('Error fetching desc:', error);
    }
  };

  const fetchGroup = async () => {
    try {
      const response = await fetch('https://www.skarvisystems.co.uk/Navigator/TESTING/phpBackend/fetch_groupNames.php');
      const data = await response.json();
      setGroup(data.result.map(item => ({ label: item.group_name, value: item.group_name })));
    } catch (error) {
      console.error('Error fetching desc:', error);
    }
  };

  // Fetch pricing quotations
  const fetchQuot = async () => {
    try {
      const response = await fetch('https://www.skarvisystems.co.uk/Navigator/TESTING/phpBackend/fetch_pricingQuot.php');
      const data = await response.json();
      setPricingQuot(data.result.map(item => ({ label: item.quotation, value: item.quotation })));
    } catch (error) {
      console.error('Error fetching quotation:', error);
    }
  };



  // Fetch broker names and their rates
  const fetchBrokerDetails = async () => {
    try {
      const response = await fetch('https://www.skarvisystems.co.uk/Navigator/TESTING/phpBackend/fetch_brokerDetails.php');
      const data = await response.json();
      setBrokerName(data.result.map(item => ({ label: item.broker_name, value: item.broker_name })));
  
      // Create maps for broker rates
      const brokerMTMap = new Map(data.result.map(item => [item.broker_name, parseFloat(item["usd/MT"])]));
      const brokerBBLMap = new Map(data.result.map(item => [item.broker_name, parseFloat(item["usd/BBL"])]));
  
      // Set the state with the maps
      setBrokerMTMap(brokerMTMap);
      setBrokerBBLMap(brokerBBLMap);
  
      // No need to handle event listeners here, they can be set directly in the JSX
    } catch (error) {
      console.error('Error fetching details:', error);
    }
    
  };

  // Calculate broker charges based on prices and quantities
  const calculateBrokerCharges = () => {
    console.log("Selected Broker Name:", selectedBrokerName);
    console.log("Selected Unit:", selectedUnit);
    console.log("MT:", MT);
    console.log("BBL:", BBL);
  
    const qtyMt = parseFloat(MT) || 0;
    const qtyBbl = parseFloat(BBL) || 0;
    const brokerName = selectedBrokerName;
  
    if (brokerName && selectedUnit) {
      let brokerCharges = 0;
  
      if (selectedUnit === 'USD/MT') {
        const usdMtValue = parseFloat(brokerMTMap.get(brokerName));
        console.log("USD/MT Value:", usdMtValue);
        brokerCharges = usdMtValue ? (usdMtValue * qtyMt).toFixed(2) : 0;
      } else if (selectedUnit === 'USD/BBL') {
        const usdBBLvalue = parseFloat(brokerBBLMap.get(brokerName));
        console.log("USD/BBL Value:", usdBBLvalue);
        brokerCharges = usdBBLvalue ? (usdBBLvalue * qtyBbl).toFixed(2) : 0;
      }
  
      console.log("Broker Charges:", brokerCharges);
      setSelectedBrokerCharges(brokerCharges.toString()); // Convert to string
    } else {
      setSelectedBrokerCharges('');
    }
    
  };


  const fetchSellerNames = async () => {
    try {
      const response = await fetch('https://www.skarvisystems.co.uk/Navigator/TESTING/phpBackend/fetch_sellerNames.php');
      const data = await response.json();
      setSellerNames(data.result.map(item => ({ label: item.seller_name, value: item.seller_name })));
    } catch (error) {
      console.error('Error fetching names:', error);
    }
  };

  const fetchTraderNames = async () => {
    try {
      const response = await fetch('https://www.skarvisystems.co.uk/Navigator/TESTING/phpBackend/fetch_traderNames.php');
      const data = await response.json();
      setTrader(data.result.map(item => ({ label: item.trader_name, value: item.trader_name })));
    } catch (error) {
      console.error('Error fetching names:', error);
    }
  };

const handleClickCMAU = () => {



}

function onOff1(aval) {
  console.log(aval);

  const fixed = document.getElementById("FIXED");
  const holiday = document.getElementById("holiday");
  const pricingQuote = document.getElementById("pricing_quote");

  if (aval === "Fixed Pricing pd.") {
    fixed.style.display = 'block';
    holiday.style.display = 'none';
    pricingQuote.style.display = 'none';
    setFixedPricingPeriodVisible(true);
    setHolidayVisible(false);
    setPricingQuoteVisible(false);
  } else if (aval === "Around BL" || aval === "Around NOR") {
    fixed.style.display = 'none';
    holiday.style.display = 'block';
    pricingQuote.style.display = 'block';
    setFixedPricingPeriodVisible(false);
    setHolidayVisible(true);
    setPricingQuoteVisible(true);
  } else {
    fixed.style.display = 'none';
    holiday.style.display = 'none';
    pricingQuote.style.display = 'block';
    setFixedPricingPeriodVisible(false);
    setHolidayVisible(false);
    setPricingQuoteVisible(true);
    // Reset input values if needed
    document.querySelector("input[name=seller_Fixed_pricing_period_from]").value = "";
    document.querySelector("input[name=seller_Fixed_pricing_period_to]").value = "";
  }
}


  

  // Handle CP description change
  const handleSelectCPdescChange = (selectedOption) => {
    setSelectedCPdesc(selectedOption ? selectedOption.value : '');
  };

  const handleSelectPortfolioChange = (selectedOption) => {
    setSelectedPortfolio(selectedOption ? selectedOption.value : '');
  };

  // Handle quotation change
  const handleSelectTSChange = (selectedOption) => {
    setSelectedTS(selectedOption ? selectedOption.value : '');
  };

  // Handle selected broker change
  const handleSelectBrokerChange = (selectedOption) => {
    setSelectedBrokerName(selectedOption ? selectedOption.value : '');
  };

  // Handle unit change
  const handleSelectUnitChange = (selectedOption) => {
    setSelectedUnit(selectedOption ? selectedOption.value : '');
  };

  const handleSelectSellerChange = (selectedOption) => {
    setSelectedSellerName(selectedOption ? selectedOption.value : '');
  };

  const handleSelectPortChange = (selectedOption) => {
    setSelectedPort(selectedOption ? selectedOption.value : '');
  };
 
  const handleSelectTermChange = (selectedOption) => {
    setSelectedTerm(selectedOption ? selectedOption.value : '');
  };
  const handleSelectOTChange = (selectedOption) => {
    setSelectedOT(selectedOption ? selectedOption.value : '');
  };
  const handleSelectIQTBChange = (selectedOption) => {
    setSelectedIQTB(selectedOption ? selectedOption.value : '');
  };
  const handleSelectPBChange = (selectedOption) => {
    setSelectedPB(selectedOption ? selectedOption.value : '');
  };
  const handleSelectPricingQuoteChange = (selectedOption) => {
    setSelectedPricingQuot(selectedOption ? selectedOption.value : '');
  };
  const handleSelectGroupChange = (selectedOption) => {
    setSelectedGroup(selectedOption ? selectedOption.value : '');
  };

  const handleSelectEsc_DescChange = (selectedOption) => {
    setSelectedEsc_Desc(selectedOption ? selectedOption.value : '');
  };
  // const handleSelectPricingBasisChange = (selectedOption) => {
  //   setSelectedPricingBasis(selectedOption ? selectedOption.value : '');
  // };

  const handleSelectPricingBasisChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedPricingBasis(selectedOption.value);
      onOff1(selectedOption.value); 
    } else {
      setSelectedPricingBasis('');
    }
  }

  const handleSelectPaymentTermsChange = (selectedOption) => {
    setSelectedPaymentTerms(selectedOption ? selectedOption.value : '');
  };

  const handleSelectGTCChange = (selectedOption) => {
    setSelectedGTC(selectedOption ? selectedOption.value : '');
  };

  const handleSelectDemurrageChange = (selectedOption) => {
    setSelectedDemurrage(selectedOption ? selectedOption.value : '');
  };
  const handleSelectTraderChange = (selectedOption) => {
    setSelectedTrader(selectedOption ? selectedOption.value : '');
  };

 
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

    return (
        <div className="outer-container">
        <header className="header">
          <h1>Skarvi Systems</h1>
        </header>
    
        <div style = {{height:"20px"}}></div>
        {/* <h3 className="subtitle">Please fill the details mentioned below</h3> */}
        <div>
        <h2 className="heading"  style = {{fontSize:"25px"}}>Purchase Contract</h2>
        </div>
        <div style = {{height:"20px"}}></div>
        <div className="row justify-content-center">
        <div className="col-md-16"> {/* Adjust the width of the card as needed */}
                        <div className="card border-dark"  style={{ height: 'fill-content', width:'100%'}}> {/* Card component */}
                            <div className="card-body" style={{ padding: "20px" }}>
                            <div className="col-md-44"> {/* Card body */}
        <div className="form-row" >
          
          <div style = {{height:"40px"}}></div>
          <form onSubmit={handleSubmit} className="form">
            
    
    
          <div class="row">
      <div class="col-sm-2 "><label htmlFor="transRef">Transaction Ref.</label>
      
            <input type="text" id="transRef" name="Transaction Ref." readOnly value={transRef}/>
            </div>
           
            <div class="col-sm-2"><label htmlFor="PCID">Purchase Contract ID</label>
      
      <input type="text" id="PCID" name="Purchase Contract ID" readOnly value={purchaseContID}/>
      </div>

      <div class="col-sm-2"><label htmlFor="destination">Destination</label>
        {
          <Select
          value={selectedDest ? { label: selectedDest, value: selectedDest} : null}
          onChange={handleSelectDestinationChange}
          // onInputChange={handleInputChange} // Handle input change
          options={destinationOptions}
          isClearable={false}
          placeholder="Select an option"
      />
        }</div>
      <div class="col-sm-2"> <label htmlFor="Cargo description">Portfolio</label>
            {
              <Select
              value={selectedPortfolio ? { label: selectedPortfolio, value: selectedPortfolio } : null}
              onChange={handleSelectPortfolioChange}
              
              options={portfolioOptions}
              isClearable={true}
              placeholder="Select portfolio"
          />
            }</div>

<div class="col-sm-2"> <label htmlFor="Cargo description">Cargo description</label>
        {
          <Select
          value={selectedCPdesc ? { label: selectedCPdesc, value: selectedCPdesc } : null}
          onChange={handleSelectCPdescChange}
          
          options={CpDesc}
          isClearable={true}
          placeholder="Select Cargo desc"
      />
        }</div>

<div class="col-sm-2"><label htmlFor="traderName">Seller Name</label>
            {
              <Select
              id='sellerName'
              value={selectedSellerName ? { label: selectedSellerName, value: selectedSellerName } : null}
              onChange={handleSelectSellerChange}
              
              options={sellerNames}
              isClearable={true}
              placeholder="Select Seller"
              // style={{ height: '45px' }} 
          />
            }</div>


    </div>
    
    <div class="row">
      <div class="col-sm-2"> 
            <label htmlFor="traderName">Trader Name:</label>
         
          <input type="text" id="traderName" name="traderName" required  value ={traderName}
          onChange={(e) => setTraderName(e.target.value)}/>
            </div>


            <div class="col-sm-2"><label htmlFor="dealDate">Deal Date:</label>
            <input type="date" id="dealDate" name="dealDate" style={{height :'40px'}}required value= {dealDate} onChange = {(e) => setDealDate(e.target.value)}/></div>
    
      <div class="col-sm-2"> 
      <label htmlFor="QTY.BBL">Term/Spot:</label>
      {
          <Select
          value={selectedTS ? { label: selectedTS, value: selectedTS } : null}
          onChange={handleSelectTSChange}
          // onInputChange={handleInputChange} // Handle input change
          options={TsOptions}
          isClearable={false}
          placeholder="Select an option"
      />
        }
    </div>

    
    
    
    
      <div class="col-sm-2"> <label htmlFor="MT">Port</label>
      {
              <Select
              id='Port'
              value={selectedPort ? { label: selectedPort, value: selectedPort } : null}
              onChange={handleSelectPortChange}
              
              options={ports}
              isClearable={true}
              placeholder="Select Port"
              // style={{ height: '45px' }} 
          />
            }</div>
    
      <div class="col-sm-2"><label htmlFor="terms">Terms</label>
      {
          <Select
          value={selectedTerm ? { label: selectedTerm, value: selectedTerm } : null}
          onChange={handleSelectTermChange}
          // onInputChange={handleInputChange} // Handle input change
          options={termsOptions}
          isClearable={true}
          placeholder="Select an option"
      />
        }</div>
 <div class="col-sm-2"> <label htmlFor="MT">Destination</label>
      {
              <Select
              id='destinstion'
              value={selectedDestination ? { label: selectedDestination , value: selectedDestination  } : null}
              onChange={handleSelectPDChange}
              
              options={pds}
              isClearable={true}
              placeholder="Select destination"
              // style={{ height: '45px' }} 
          />
            }</div>

    </div>
    
    <div class="row">

      <div class="col-sm-2"><label htmlFor="norAtDestination">NOR at Dest.</label>
            <input type="date" id="norAtDestination" name="norAtDestination" style={{height :'40px'}}required value= {norAtDestination} onChange = {(e) => setNorAtDestination(e.target.value)}/></div>
    
            <div class="col-sm-2"><label htmlFor="loadingLaycanfrom">Loading Laycan From</label>
            <input type="date" id="loadingLaycanfrom" name="loadingLaycanfrom" style={{height :'40px'}}required value= {loadingLaycanfrom} onChange = {(e) => setLoadingLaycanFrom(e.target.value)}/></div>

            <div class="col-sm-2"><label htmlFor="loadingLaycanto">Loading Laycan To</label>
            <input type="date" id="loadingLaycanto" name="loadingLaycanto" style={{height :'40px'}}required value= {loadingLaycanto} onChange = {(e) => setLoadingLaycanTo(e.target.value)}/></div>
    
           < div class="col-sm-2"> 
            <label htmlFor="Narrowed Down to">Narrowed Down to</label>
         
          <input type="text" id="Narrowed Down to" name="Narrowed Down to" required  value ={narrowedDownTo}
          onChange={(e) => setNarrowedDownTo(e.target.value)}/>
            </div>

            <div class="col-sm-2"><label htmlFor="daysBy">Days BY</label>
            <input type="date" id="daysBy" name="daysBy" style={{height :'40px'}}required value= {daysBy} onChange = {(e) => setDaysBy(e.target.value)}/></div>
    
            <div class="col-sm-2"><label htmlFor="BL Date">BL Date</label>
            <input type="date" id="BL Date" name="BL Date" style={{height :'40px'}}required value= {BLdate} onChange = {(e) => setBLdate(e.target.value)}/></div>

      
    </div>
    
    <div class="row">

    <div class="col-sm-2"><label htmlFor="BL Date">Nor Date</label>
            <input type="date" id="Nor Date" name="Nor Date" style={{height :'40px'}}required value= {Nordate} onChange = {(e) => setNordate(e.target.value)}/></div>
      
            <div class="col-sm-2"><label htmlFor="EST BL Date">EST. BL Date</label>
            <input type="date" id="EST BL Date" name="EST BL Date" style={{height :'40px'}}required value= {EstBLdate} onChange = {(e) => setEstBLdate(e.target.value)}/></div>
      
            <div class="col-sm-2"><label htmlFor="EST BL Date">EST. Nor Date</label>
            <input type="date" id="EST. Nor Date" name="EST. Nor Date" style={{height :'40px'}}required value= {EstNordate} onChange = {(e) => setEstNordate(e.target.value)}/></div>

      
      
    </div>
          
    <div class="row">
     
    < div class="col-sm-2"> 
            <label htmlFor="seller_contract_quantiity_mton_air">Contr. Qty. Mtons Air</label>
         
          <input type="text" id="seller_contract_quantiity_mton_air" name="seller_contract_quantiity_mton_air" required  value ={SC_QtyMtAir}
          onChange={(e) => setSC_QtyMtAir(e.target.value)}/>
            </div>

          
        <div className='col-sm-1'>
        <button type="button" name = "cmau"  id = "cmau"  class="btn btn-secondary btn-outline-light  " style={{marginTop: '23px', width:'90px',height:'50px'}}>U</button>
        </div>

        < div class="col-sm-2"> 
            <label htmlFor="seller_contract_quantiity_barrels">Contr. Qty. Barrels</label>
         
          <input type="text" id="seller_contract_quantiity_barrels" name="seller_contract_quantiity_barrels" required  value ={SC_QtyMtBBL}
          onChange={(e) => setSC_QtyMtBBL(e.target.value)}/>
            </div>

            <div className='col-sm-1'>
        <button type="button" name = "cbblu"  id = "cbblu"  class="btn btn-secondary btn-outline-light  " style={{marginTop: '23px', width:'90px',height:'50px'}}>U</button>
        </div>
        
        < div class="col-sm-2"> 
            <label htmlFor="seller_contract_specific_gravity">Cont Density</label>
         
          <input type="text" id="seller_contract_specific_gravity" name="seller_contract_specific_gravity" required  value ={SC_SpecGra}
          onChange={(e) => setSC_SpecGra(e.target.value)}/>
            </div>

            < div class="col-sm-2"> 
            <label htmlFor="seller_loaded_specific_gravity">Loaded Density</label>
         
          <input type="text" id="seller_loaded_specific_gravity" name="seller_loaded_specific_gravity" required  value ={SL_SpecGra}
          onChange={(e) => setSL_SpecGra(e.target.value)}/>
            </div>

            < div class="col-sm-2"> 
            <label htmlFor="seller_price_calculation_density">Price Density</label>
         
          <input type="text" id="seller_price_calculation_density" name="seller_price_calculation_density" required  value ={SP_CD}
          onChange={(e) => setSP_CD(e.target.value)}/>
            </div>
      
    </div>

<div className='row'>
  
< div class="col-sm-2"> 
            <label htmlFor="seller_Operational_Tolerence">O.T. %</label>
         
          <input type="number" id="seller_Operational_Tolerence" name="seller_Operational_Tolerence" required  value ={OT}
          onChange={(e) => setOT(e.target.value)} style={{height:'40px'}}/>
         
            </div>

            <div class="col-sm-2"><label htmlFor="Opper tol option">Oper. Tol. Option</label>
      {
          <Select
          value={selectedOT ? { label: selectedOT, value: selectedOT } : null}
          onChange={handleSelectOTChange}
          // onInputChange={handleInputChange} // Handle input change
          options={OTOptions}
          isClearable={true}
          placeholder="Select an option"
      />
        }</div>

<div class="col-sm-1"><label htmlFor="Pricing Unit">Pricing Unit</label>
      {
          <Select
          value={selectedUnit ? { label: selectedUnit, value: selectedUnit } : null}
          onChange={handleSelectUnitChange}
          // onInputChange={handleInputChange} // Handle input change
          
          options={UnitOptions}
          isClearable={false}
          placeholder="Select"
      />
        }</div>

<div class="col-sm-1"><label htmlFor="Inv. QT. B">Inv. QT. B</label>
      {
          <Select
          value={selectedIQTB ? { label: selectedIQTB, value: selectedIQTB } : null}
          onChange={handleSelectIQTBChange}
          // onInputChange={handleInputChange} // Handle input change
          options={IQTBOptions}
          isClearable={false}
          placeholder="Select"
      />
        }</div>

        <div class="col-sm-2"><label htmlFor="P.B">P.B.</label>
      {
          <Select
          value={selectedPB ? { label: selectedPB, value: selectedPB } : null}
          onChange={handleSelectPBChange}
          // onInputChange={handleInputChange} // Handle input change
          options={PBOptions}
          isClearable={true}
          placeholder="Platts Assessment"
      />
        }</div>

<div class="col-sm-2"><label htmlFor="Group Name">Group Name</label>
      {
          <Select
          value={selectedGroup ? { label: selectedGroup, value: selectedGroup } : null}
          onChange={handleSelectGroupChange}
          // onInputChange={handleInputChange} // Handle input change
          options={GroupOptions}
          isClearable={true}
          placeholder="Select"
      />
        }</div>

<div class="col-sm-2"><label htmlFor="Pricing Quote">Pricing Quote</label>
      {
          <Select
          value={selectedQuote ? { label: selectedQuote, value: selectedQuote } : null}
          onChange={handleSelectPricingQuoteChange}
          // onInputChange={handleInputChange} // Handle input change
          options={PricingQuoteOptions}
          isClearable={true}
          placeholder="Select"
      />
        }</div>

</div>

<div className='row'>

<div class="col-sm-2"><label htmlFor="Pricing Basis">Pricing Period Basis</label>
      {
          <Select
          value={selectedPricingBasis ? { label: selectedPricingBasis, value: selectedPricingBasis } : null}
          onChange={handleSelectPricingBasisChange}
          // onInputChange={handleInputChange} // Handle input change
          options={PricingBasisOptions}
          isClearable={true}
          placeholder="Select"
      />
        }</div>

<div class="col-sm-2" id = "pricing_quote" style={{display:'none'}}>

<label  >Pricing Quotes (Days)</label>

    <input type="number"  name="seller_pricing_quotes" style={{height :'35px'}} value={priceQD} onChange = {(e) => setpriceQD(e.target.value)}></input>

          

</div>

<div class="form-group col-sm-6" id="FIXED"  style={{display:'none'}}>

<div class="row">

   <div class="col-sm-6">

       <label  class="control-label required">Fixed Pricing Period Basis From</label>

       <div class='input-group date' id='datetimepicker1'>

       <input type="date"  name="seller_Fixed_pricing_period_from" style={{height :'35px'}} required value={Fix_PPBF} onChange = {(e) => setFix_PPBF(e.target.value)} ></input>


       </div>

       

       </div>
       

       <div class="col-sm-6">

<label  class="control-label required">Fixed Pricing Period Basis To</label>

<div class='input-group date' id='datetimepicker2'>

<input type="date"  name="seller_Fixed_pricing_period_to" style={{height :'35px'}}  required value={Fix_PPBT} onChange = {(e) => setFix_PPBT(e.target.value)}></input>



</div>



</div>

</div>

</div>

<div id = "holiday" style={{display:'none'}}>

<div class="col-sm-4">

   <label  class="control-label">Pricing Period Basis Holiday1</label>

   <input type="number" name="pricing_period_basis_holiday_1" style={{height :'37px'}} value={PPBH1} onChange = {(e) => setPPBH1(e.target.value)}></input>

</div>

<div class="col-sm-4">

    <label  class="control-label">Pricing Period Basis Holiday2</label>

   <input type="number"  name="pricing_period_basis_holiday_2" style={{height :'37px'}} value={PPBH2} onChange = {(e) => setPPBH2(e.target.value)}></input>

</div>

</div>



</div>

<div style = {{height:"20px"}}></div>


<div className='row'>

< div class="col-sm-2"> 
            <label htmlFor="seller_premium_discount">Premium/Discount</label>
         
          <input type="text" id="seller_premium_discount" name="seller_premium_discount" required  value ={P_D}
          onChange={(e) => setP_D(e.target.value)}/>
            </div>

            
            < div class="col-sm-2"> 
            <label htmlFor="seller_price_rounded">Price to be rounded to</label>
         
          <input type="number" id="seller_price_rounded" name="seller_price_rounded" required  value ={SP_Rounded}
          onChange={(e) => setSP_Rounded(e.target.value)} style={{height:'40px'}}/>
            </div>
            
            <div class="col-sm-2"><label htmlFor="esc_de_esc_value">Esc/D-Esc</label>
      {
          <Select
          value={selectedEsc_Desc ? { label: selectedEsc_Desc, value: selectedEsc_Desc } : null}
          onChange={handleSelectEsc_DescChange}
          // onInputChange={handleInputChange} // Handle input change
          options={Esc_DescOptions}
          isClearable={true}
          placeholder="Select"
      />
        }</div>

<div class="col-sm-2"><label htmlFor="seller_payment_terms">Payment Terms</label>
      {
          <Select
          value={selectedPaymentTerms ? { label: selectedPaymentTerms, value: selectedPaymentTerms } : null}
          onChange={handleSelectPaymentTermsChange}
          // onInputChange={handleInputChange} // Handle input change
          options={PaymentTermsOptions}
          isClearable={true}
          placeholder="Select"
      />
        }</div>

        
</div>

<div className='row'>

< div class="col-sm-2"> 
            <label htmlFor="seller_price_rounded">Laytime Hours</label>
         
          <input type="number" id="seller_Laytime_Hours" name="seller_Laytime_Hours" required  value ={LayHours}
          onChange={(e) => setLayHours(e.target.value)} style={{height:'40px'}}/>
            </div>


            <div class="col-sm-2"><label htmlFor="gtc">GTC</label>
      {
          <Select
          value={selectedGTC? { label: selectedGTC, value: selectedGTC } : null}
          onChange={handleSelectGTCChange}
          // onInputChange={handleInputChange} // Handle input change
          options={GTCOptions}
          isClearable={true}
          placeholder="Select"
      />
        }</div>

<div class="col-sm-2"><label htmlFor="Demurrage">Demurrage</label>
      {
          <Select
          value={selectedDemurrage? { label: selectedDemurrage, value: selectedDemurrage } : null}
          onChange={handleSelectDemurrageChange}
          // onInputChange={handleInputChange} // Handle input change
          options={DemurrageOptions}
          isClearable={true}
          placeholder="Select"
      />
        }</div>

<div class="col-sm-2"><label htmlFor="traded_by">Traded By</label>
      {
          <Select
          value={selectedTrader? { label: selectedTrader, value: selectedTrader } : null}
          onChange={handleSelectTraderChange}
          // onInputChange={handleInputChange} // Handle input change
          options={TraderOptions}
          isClearable={true}
          placeholder="Select"
      />
        }</div>

<div class="col-sm-2"><label htmlFor="trade_created">Trade Created on</label>
            <input type="date" id="trade_created" name="trade_created" style={{height :'40px'}}required value= {TradeCreatedOn} onChange = {(e) => setTradeCreatedOn(e.target.value)}/></div>



</div>





   



          
    
            {/* <button  onClick={calculateBrokerCharges}>calculate Broker Charges </button> */}
            <div className="row">
        <div className="col-md-3 offset-md-5">
            <button type="submit" className="btn btn-primary btn-block">Submit</button>
        </div>
    </div>
          </form>
          </div>
          </div>
          </div>
          </div>
          </div>
          
        </div>
      </div>

    );

}


export default PurchaseContract;